import ApplicationController from './application_controller'
import checkbox_yes from '../images/icons/checkbox-yes.svg'
import checkbox_no from '../images/icons/checkbox-no.svg'

export default class extends ApplicationController {
	static targets = ["checkbox"]
	getSelected(){
		return this.checkboxTargets.filter(function(e){
			return e.hasAttribute("data-selected")
		})
	}
	flip(e, is_selected) {
		let input = e.querySelector("input")
		let img = e.querySelector(".checkbox-class")
		if(is_selected) {
			e.removeAttribute('data-selected')
			img.setAttribute('src', checkbox_no)
			img.classList.remove("filter-green")
			img.classList.add("filter-red")
			input.checked = false
		} else {
			e.setAttribute('data-selected', true)
			img.setAttribute('src', checkbox_yes)
			img.classList.remove("filter-red")
			img.classList.add("filter-green")
			input.checked = true
		}
	}
	check(event) {
		let e = event.currentTarget
		let is_selected = e.hasAttribute('data-selected')
		let all_selected = this.getSelected()
		if((!is_selected) && all_selected.length >= 3) { //if you are about to select a fourth
			alert("You can only select 3 classifications! Try unselecting an existing classification to select a new one.")
		} else {
			this.flip(e, is_selected)
		}

	}
}
