import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = [""]
	connect () {
		this.f = this.handleClick.bind(this) //love bind now. There was a long time where i was like wtf is javascript even doing, and then i understood bind.
		document.addEventListener('click', this.f);
	}

	disconnect() {
		document.removeEventListener('click', this.f);
	}

	toggleAccordion(event) {
		const clickedElement = event.currentTarget;
		const activeElements = document.querySelectorAll('.cat-clicked');
		activeElements.forEach(
			element => {
				element.classList.remove("cat-clicked");
			}
		)

		clickedElement.classList.add("cat-clicked");
		// this.accordionTarget.classList.remove('hidden');
	}
	
	handleClick(event) {
		const clickedElement = event.target;
		const controllerElement = this.element;
		const activeElements = document.querySelectorAll('.cat-clicked');
	
		if (!controllerElement.contains(clickedElement)) {
			// Click occurred outside the controller element
			// Perform desired actions
			activeElements.forEach(
				element => {
					element.classList.remove("cat-clicked");
				}
			)
		}
	}

}