export default {
	unitName: "SearchBar",
	regBar(e){
		if(e.type == "keyup" && e.key != "Enter"){ return }
		//get what we are searching for
		let q = this.el.qs('input').value
		if(this.el.ds.username){
			q = this.el.ds.username + " " + q
		}
		//create the url and goto it using otty
		this.gotoSearch(q, e)
	},
	searchPageBar(e){
		if(e.type == "keyup" && e.key != "Enter"){
			this.dive(e, {behavior: 'delay', stagger: 500, allowDefault: true, withform: true})	
			 return
		}
		
		this.gotoSearch(this.el.qs('input').value, e)
	},
	gotoSearch(q, e){
		let url = new URL(this.el.ds.path, window.location)
		url.searchParams.set('q', q)
		e.preventDefault()
		otty.goto(url)
	},
}