import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = ["seeChanges", "dropdownToggle", "dropdownItem", "dropdownItemText", "dropdownMenu", "container"]

	connect () {
		super.connect()
	}
	toggleChanges(){
		let sct = this.seeChangesTarget

		if (sct.classList.contains("rc-toggle")){
			sct.classList.remove("rc-toggle")
			this.element.classList.remove("see-changes")
			this.element.classList.remove("see-removed")
		} 
		else {
			sct.classList.add("rc-toggle")
			this.element.classList.add("see-changes")
			this.element.classList.add("see-removed")
		}
	}

	showSection(){
		const sectionId = event.target.closest("[data-section]").getAttribute("data-section");
		this.setActiveSection(sectionId);
		this.setDropdownToggleText();
		this.dropdownMenuTarget.classList.remove("show");
	}

	setActiveSection(sectionId) {
		// show content selected. 
		
		// Diffy classes for changes won
		this.containerTarget.classList.remove("see-changes", "see-removed");
		if (sectionId == "seeChanges"){
			this.containerTarget.classList.add("see-changes", "see-removed")
		}

		// Adjust dropdown to have active state. 
		this.dropdownItemTargets.forEach((item) => {
			item.classList.remove("active");
		});

		//Set active dropdown option.
		this.dropdownItemTargets.forEach((item) => {
			item.classList.toggle("active", item.getAttribute("data-section") === sectionId);
		});
    }

	setDropdownToggleText() {
		const activeSection = this.dropdownMenuTarget.querySelector(".dropdown-item.active");
		this.dropdownToggleTarget.textContent = activeSection ? activeSection.getAttribute("data-btn-text") : "Select Section";
	}
}