//import Turbolinks from 'turbolinks'
import ApplicationController from './application_controller'

export default class extends ApplicationController {
	// where we tell stimulus the variables we are using
	static targets = [ "message", "bottom" ]

	obs_hell(m, target, meth) {
		let options = {
			rootMargin: m
		}
		let observer = new IntersectionObserver(meth, options)
		observer.context = this
		observer.observe(target)
		return observer
	}

	//on connect subscribe to cable ready updates
	connect () {
		//https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
		this.last_request = null

		this.obs_hell('750px', this.bottomTarget, this.get_next_page_obs)
		this.obs_hell('300px', this.bottomTarget, this.get_next_page_obs)
		let an_observer = this.obs_hell('0px', this.bottomTarget, this.get_next_page_obs)

		let context = this
		//sometimes on post pages the initial load would not fill the entire page + the root margin, so this is to compensate.
		//this is generally for comments.
		if(window.location.href.includes("/posts/")){
			setTimeout( function() {
				an_observer.unobserve(context.bottomTarget)
				an_observer.observe(context.bottomTarget)
			}, 5000 + Math.random(5) * 1000)
		}
		
		super.connect()
	}

	get_next_page_obs(entries, observer) {
		for(let x = 0; x < entries.length; x ++ ) {
			if(entries[x].isIntersecting) {
				//console.log("passing get_next_page_obs")
				observer.context.get_next_page()
			}
		}
	}

	// refresh_side_height() {
	// 	console.log("AAAA")
	// 	let l = document.getElementById("left_side")
	// 	if(l != undefined) {
	// 		let r = document.getElementById("right_side")
	// 		let h = document.getElementById('side_height_reference').clientHeight + "px"
	// 		l.style.height = h
	// 		r.style.height = h
	// 	}
	// }

	get_next_page() {
		let there_is_more = !this.messageTarget.hasAttribute("data-halt")
		
		//I had this time-based stuff because I was worried about timeouts or something
		// but then i realized I was not working with websockets anymore and it would be fine.

		//fuck websockets what a waste of fucking time

		// if(this.last_request == undefined) {
		// 	too_soon = false
		// } else {
		// 	too_soon = ( Date.now() - this.last_request ) > 5000 // five seconds.
		// }

		if( there_is_more && this.last_request == null) {
			this.last_request = Date.now()
			let context = this
			//this could of been a form but I am porting from cableready
			document.tripwire("/api/scroll", {
				form_info: {
					"data-feed": this.messageTarget.getAttribute("data-feed"),
					"id": this.messageTarget.getAttribute("id"),
					"data-last-id": this.messageTarget.getAttribute("data-last-id"),
					"data-filter": this.messageTarget.getAttribute("data-filter"),
					"data-comment-level": this.messageTarget.getAttribute("data-comment-level")
				}
			}).finally((out) => {
				context.last_request = null
			})
		}
	}

}