import checkbox_yes from '../images/icons/checkbox-yes.svg'
import checkbox_no from '../images/icons/checkbox-no.svg'
import ApplicationController from './application_controller'

export default class extends ApplicationController {
	connect () {
	}

	yes(e){
		let yes = e.currentTarget
		let nm = yes.id.split('@')[0]
		let no = document.getElementById(nm + '@no')
		
		this.element.dataset[nm] = 'true'
		yes.classList.add('questionare-yes-btn')
		no.classList.remove('questionare-no-btn')

	}
	no(e){
		let no = e.currentTarget
		let nm = no.id.split('@')[0]
		let yes = document.getElementById(nm + '@yes')
		
		delete this.element.dataset[nm]
		no.classList.add('questionare-no-btn')
		yes.classList.remove('questionare-yes-btn')
	}
	
	check(e){
		let ct = e.currentTarget
		if(this.element.dataset[ct.id] == 'true') {
			delete this.element.dataset[ct.id]
			ct.querySelector('img').src = checkbox_no
		} else {
			this.element.dataset[ct.id] = 'true'
			ct.querySelector('img').src = checkbox_yes
		}
	}
}


