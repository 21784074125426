import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = ["authorPromoteLarge", "authorPromoteSmall", "openBtn", "closeBtn"]
	connect () {
	}

	open(){
		this.authorPromoteSmallTarget.classList.add("display-none")
		this.authorPromoteLargeTarget.classList.remove("display-none")
	}

	close(){
		this.authorPromoteLargeTarget.classList.add("display-none")
		this.authorPromoteSmallTarget.classList.remove("display-none")
	}

}