export default {
	unitName: "Comment",
	// static targets = ["createCommentBtn"]
	cover: () => {
		this.createCommentBtnTarget.classList.add('create-comment-cover')
		var context = this
		setTimeout(function(){
			context.createCommentBtnTarget.classList.remove('create-comment-cover')
		}, 2000)
	},
	changeinput: (e) => {
		console.log(e)
		let value =  e.ct.getAttribute('data-value')
		console.log(value)
		let name =  e.ct.getAttribute('data-name')
		let parent = document.getElementById("comment_category") //theoretically should only be one.
		let other_parent =  document.getElementById("comment_category_button")
		console.log(parent, other_parent)
		parent.setAttribute('value', value)
		other_parent.innerHTML = name
		// find element of the hidden Input and change its value
	},
	get_params: () => {
		return {
			'data-open': this.element.getAttribute('data-open'),
			'data-object': this.element.getAttribute('id'),
			'data-type': this.element.getAttribute('data-type'),
			'data-comment-level': this.element.getAttribute('data-comment-level')
		}
	}
}