import ApplicationController from './application_controller'
export default class extends ApplicationController {
	static values = {
		recipient: String,
		subject: String,
		body: String
	}
	
	connect() {
		
	}
	
	autofill() {
		const recipient = this.recipientValue || "josh@midflip.io"
		const subject = this.subjectValue || "Inquiry about Midflip Enterprise"
		const body = this.bodyValue || "Hello,\n\nI'm interested in learning more about midflip. Could you please provide me with additional information?\n\nThank you,\n[Your Name]"
	
		const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
		
		try {
			// Try to open in a new window/tab
			const newWindow = window.open(mailtoLink, '_blank')
			
			// If blocked, newWindow will be null
			if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
			  throw new Error('Popup blocked')
			}
		  } catch (e) {
			// Fallback: navigate in the same tab
			window.location.href = mailtoLink
		}

	}

	
}