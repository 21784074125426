import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = ["centeredNotification"]
	connect () {
	}

	close(){
		this.centeredNotificationTarget.classList.add("display-none")
		document.getElementById("create-post-center").style.cssText = 'margin-top:2rem !important;';
	}

}