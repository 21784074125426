export default {
	unitName: "TopicCreate",
	onConnected(){
		//remove this after I have updated otterly like twice
		Object.defineProperties(HTMLElement.prototype, {
			cl: {
				get: function(){return this.classList}
			}
		})


		this.firstHeader = this.el.qs('#first-header')
		this.secondHeader = this.el.qs('#second-header')
		this.continueButton =  this.el.qs('#continue')
		this.emojiPuke = this.el.qs("#emoji-puke")
	},
	first(e){
		//if the first is not open open it, close the second. Else do nothign
		if(this.firstHeader.cl.contains('show')){ return }

		this.firstHeader.cl.add('show')
		this.firstHeader.cl.remove('collapsed')

		this.secondHeader.cl.remove('show')
		this.secondHeader.cl.add('collapsed')

		this.continueButton.cl.remove('display-none')

		this.emojiPuke.cl.add('display-none')
	},
	second(e){
		if(this.secondHeader.cl.contains('show')){ return }

		this.firstHeader.cl.remove('show')
		this.firstHeader.cl.add('collapsed')

		this.secondHeader.cl.add('show')
		this.secondHeader.cl.remove('collapsed')

		this.continueButton.cl.add('display-none')

		this.emojiPuke.cl.remove('display-none')
	}
}