import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = ['choice']
	connect () {
		super.connect()
	}
	flip(e){
		let x = 0
		while(x < this.choiceTargets.length) {
			this.choiceTargets[x].classList.remove("accordion-expand")
			this.choiceTargets[x].classList.add("accordion-small")
			x =x +1
		}
		e.currentTarget.classList.remove("accordion-small")
		e.currentTarget.classList.add("accordion-expand")
	}
}