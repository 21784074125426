import ApplicationController from './application_controller'

export default class extends ApplicationController {
	//main differences is messageTarget variables moved to this.element, and we are updating two columns, etc.

	// For more see the application_controller.js class, example_controller.js class, 
	// https://stimulusjs.org/reference/targets
	// https://docs.stimulusreflex.com/working-with-forms

	// where we tell stimulus the variables we are using

	//on connect subscribe to cable ready updates
	obs_hell(m, target, meth) {
		let options = {
			rootMargin: m
		}
		let observer = new IntersectionObserver(meth.bind(this), options)
		observer.observe(target)
	}

	connect () {
		//https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
		this.last_request = undefined

		let [l, r] = [document.getElementById('leftSideBottom'), document.getElementById('rightSideBottom')]

		this.obs_hell('500px', l, this.get_next_page_left)
		this.obs_hell('500px', r, this.get_next_page_right)
		this.obs_hell('0px', l, this.get_next_page_left)
		this.obs_hell('0px', r, this.get_next_page_right)

		this.main_feed = document.getElementById("side_height_reference")
		let resize_middle = new ResizeObserver(() => this.refresh_side_height())
		resize_middle.observe(this.main_feed)
		this.refresh_side_height()

		//oftentimes the initial load would not fill the entire page + the root margin, so this is to compensate
		super.connect()
	}

	get_next_page_left(entries, observer) {
		this.get_next_page_obs(entries, "left")
	}

	get_next_page_right(entries, observer) {
		this.get_next_page_obs(entries, "right")
	}

	get_next_page_obs(entries, side) {
		for(let x = 0; x < entries.length; x ++ ) {
			if( entries[x].isIntersecting ) {
				this.get_next_page(side)
			}
		}
	}

	refresh_side_height() {
		let shr = document.getElementById('side_height_reference')
		if(shr == undefined) {
			return
		}
		let h = shr.clientHeight + "px"
		let l = document.getElementById("left_side")
		let r = document.getElementById("right_side")
		// window.getComputedStyle(l).overflowY = "hidden";
		// window.getComputedStyle(r).overflowY = "hidden";
		l.style.height = h
		r.style.height = h
	}

	get_next_page(side) {
		let there_is_more = !this.element.hasAttribute("data-halt")

		if( there_is_more && this.last_request == undefined) {
			this.last_request = Date.now()
			document.tripwire('/api/side_scroll', {
				form_info: {
					"data-feed": this.element.getAttribute("data-feed"),
					"id": this.element.getAttribute("id"),
					"data-side": side
				}
			}).finally((()=>{
				this.last_request = undefined
				this.refresh_side_height()
			}).bind(this))
		}
	}
}