import ApplicationController from './application_controller'

export default class extends ApplicationController {
	connect () {
	}
	show_api_key(){
		hid = document.getElementById("api_key_hidden")
		no_hid = document.getElement('api_key_show')
		no_hid.classList.remove('disNone')
		hid.classList.add('disNone')
	}
	upload_icon(e){
		let input = document.createElement('input');
		input.type = 'file'
		input.accept = "image/*"
		let context = this
		let path = e.currentTarget.dataset.path
		input.onchange = e => {
			//2. append the file as form_data with the file name
			let f = e.target.files[0]
			if(f.size >= 600000) { //see the file_size_limitable.rb file
				alert('file is too big!')
				return
			}
			let form_data = new FormData();
			form_data.append('icon', f, f.name);
			//3. use the meta quick_xhr method to submit the file
			document.tripwire(path, {form_info: form_data})
		}
		input.click()
	}
}