import ApplicationController from './application_controller'

export default class extends ApplicationController {
	connect(){
		let wait = Number(this.element.dataset.time)
		this.forever()
	}
	forever(){
		let countdown = (t) => {
			let cd = document.querySelector('#update_countdown')
			if(cd != null && t > 0) {
				cd.innerHTML = "" + t
				t = t - 1
				setTimeout(countdown, 1000, t)
			}
		}
		let update = (path, wait, dataset, wlh) => {
			document.tripwire(path, {form_info: dataset}).then((x)=>{
				if( (window.location.href != wlh) || (x != "stop") ){
					countdown(wait / 1000)
					setTimeout(update, wait, path, wait, dataset, wlh)
				}
			})
		}
		let a = this.element.dataset.path
		let b = Number(this.element.dataset.time)
		let c = this.element.dataset
		let d = window.location.href
		countdown(b / 1000)
		update(a, b, c, d)
	}
}