import ApplicationController from './application_controller'

export default class extends ApplicationController {
	connect(){
		console.log("AAAA")
	}
	
	search(e){
		let ct = e.currentTarget
		let [url, meth, fd] = document.tripwire_get_data(ct.closest('form'), ct)
		console.log(ct, url, meth, fd)
		document.tripwire(url, {
			method: meth,
			form_info: fd
		})
	}
}
