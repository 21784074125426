import ApplicationController from './application_controller'

export default class extends ApplicationController {
	follow_link(e) {
		// console.log(e.target)
		if(! e.target.hasAttribute('data-dont-follow')) {
			otty.goto(e.currentTarget.getAttribute("data-link"))
		}
	}
	flip_conversation_view(e){
		let ct = e.currentTarget
		let view = ct.getAttribute("data-view")
		//- flip between messages and posts area using display: none styles.
		//- update the data-view attribute which is what we are using to keep track of this flipping
		//- on first load of posts, load the posts through stimulus reflex
		if(view == "messages") {
			let e = document.getElementById("posts_area")
			e.removeAttribute("style")
			document.getElementById("messages_area").setAttribute("style", "display: none;")
			ct.setAttribute("data-view", "posts")
			ct.classList.add('conv_post_view')
			ct.classList.remove('conv_message_view')
			if(e.childNodes.length == 0){
				this.stimulate('Open#show_conversation_posts')
			}
		} else {
			document.getElementById("messages_area").removeAttribute("style")
			document.getElementById("posts_area").setAttribute("style", "display: none;")
			ct.setAttribute("data-view", "messages")
			ct.classList.remove('conv_post_view')
			ct.classList.add('conv_message_view')
		}
	}
	media_expand(e) {
		console.log(e.currentTarget)
		e.preventDefault()
		e.stopPropagation()
		let ct = e.currentTarget
		let ifurl = ct.dataset.iframeUrl
		let dialog = document.querySelector("#media_expand_area")
		if(dialog == null){
			document.querySelector('body').insertAdjacentHTML('afterbegin', "<dialog id='media_expand_area' class='media_expand_area'></dialog>")
			dialog = document.querySelector("#media_expand_area")
			dialog.addEventListener('click', () => {dialog.close()})
		}
		if(ifurl && dialog.dataset.loaded != ifurl){
			dialog.innerHTML =  "<div class='post_video_container post_card_video_container'><iframe class='post_card_video post_video' src='" + ifurl + "'></iframe></div>"
			dialog.dataset.loaded = ifurl
		}
		dialog.showModal()
	}
	open_create_comment() {
		let open_comment = document.querySelector('[data-open=\'reply_area\']')
		document.getElementById("comment-feed-controls").scrollIntoView();
	}
}