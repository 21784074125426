import ApplicationController from './application_controller'

export default class extends ApplicationController {
	connect () {
		super.connect()
		this.emojiList = [
			["💾", "📡", "🛰️"],
			["🧍‍♂️", "🚶‍♂️", "🏃‍♂️"],
			["😘", "🎁", "😍"],
			["🍕","😮","🤤"],
			["🥱","😴","😪"],
			["🤡","😱","💀"],
			["🤫","👽","😳"],
			["🍆","😑","🙄"],
			["🌶","🥵","😭"],
			["🌌","🔭","🤩"],
			["🤓","🤖","🏜"],
			["🏂","😁","🥶"],
			["🌾","🔥","🙃"],
			["⏲","🐷","✈"],
			["🙈","🙉","🙊"],
			["🦄","🐲","🐫"],
			["🐮","💩","🤯"],
			["😨", "😅", "🤮"]
		]
		this.x = -1
		this.y = 0
		this.emojiSequenceEl = this.element.querySelector(".emoji_sequence")
	}

	emoji_step(){
		let x = this.x
		let y = this.y
		y = y % 4
		if (y == 0){
			x = x + 1
			if (x == this.emojiList.length){
				x = 0
			}
		}
		let s = this.emojiList[x]
		let t = ""
		for (let i=0; i < y; i++){
			t += s[i]
		}
		this.emojiSequenceEl.innerHTML = t
		y += 1
		this.x = x
		this.y = y
	}

	loop_emoji(){
		var context = this
		setTimeout(function(){
			if(context.element.classList.contains('loading_sequence')) { //so we can disable
				context.emoji_step()
				context.loop_emoji()
			}
		}, 600)
	}

	pressed(e){
		//ANY CHANGES HERE HAVE TO BE MIRRORED IN application_controller#kill_any_loading_butts_below
		//OK?

		this.element.classList.add('loading_sequence')

		e.currentTarget.style.display = "none"
		e.currentTarget.classList.add("display-none")
		this.element.disabled = true
		
		this.emoji_step()
		this.loop_emoji()
	}
}