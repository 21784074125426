export default {
	unitName: "Drop",
	onConnected(){
		//set initial closed state
		this.kid = this.el.children[0]
		this.open = false
		this.setStyle()
		
		//toggle entry click
		this.kid.addEventListener('click', this.toggle.bind(this))
		
		//if it resizes dont mes up.
		this.ro = midflip.rob(this.kid, (() => {
			if((!this.open)){
				this.setStyle()
			}
		}).bind(this))

		this.el.classList.add("editorDrop")
		this.el.classList.add("nonEditorPocket")

	},
	setStyle(){
		if(this.open){
			this.el.style = `transition:max-height 1s ease-in-out;`
			this.el.classList.remove("editorDropClosed")
			this.el.classList.add("editorDropOpen")
			
		} else {
			let toggle_h = `${this.kid.offsetHeight}px`
			this.el.style = `max-height:${toggle_h};overflow:hidden;transition:max-height 0.5s ease-out;`
			this.el.classList.remove("editorDropOpen")
			this.el.classList.add("editorDropClosed")
		}
	},
	toggle(e, fast){
		this.open = !this.open

		this.setStyle()
		
		e.preventDefault();
		e.stopPropagation()
	},
	onRemoved(){
		console.log("REM")
		this.ro?.disconnect()
	}

}
