// holy gospel: https://github.com/ueberdosis/tiptap/issues/2486#issuecomment-1454216894
export default {
	unitName: "Editor",
	onConnected() {
		if(this.el.ds.hashWait != undefined){
			this.editorLoadedPromise = new Promise(((res, rej) => {
				this.editorLoadedPromiseResolve = res
			}).bind(this))
		}

		if(!(this.editor)) { //< Make sure naught already loaded.
			this.chooseWhenToLoadEditor()
		}
		this.buttons = {
			bold: this.element.querySelector('[data-tiptap="bold"]'),
			italic: this.element.querySelector('[data-tiptap="italic"]'),
			underline: this.element.querySelector('[data-tiptap="underline"]')
		}
	},
	onRemoved(){
		if(this.editor){
			this.saveContent()
			this.editor.destroy()
			this.editor = undefined
		}
		this.element.querySelector('.mf_editor').innerHTML = ''
	},
	chooseWhenToLoadEditor(){
		//this method sets this.editor
		
		//If the text is in an expandable comment area, then we want to delay the load until it expands
		let comp = this.element.closest('#comment_expandable')
		if(comp && !(comp.classList.contains('show'))) {
			const options = {
			  attributes: true
			}
			let mutF = ((mutationList, observer) => {
				for(let mutation of Array.from(mutationList)){
					if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
						if(
							mutation.target.classList.contains('collapsing') ||
							mutation.target.classList.contains('show')
						){
							observer.disconnect() //kill the observations and load the text area.
							this.doImportAndSetup()
							return
						}
					}
				}
			}).bind(this)
			const observer = new MutationObserver(mutF)
			observer.observe(comp, options)
		} else {
			//this is not in an expandable area, so just load it now.
			this.doImportAndSetup()
		}
	},
	doImportAndSetup() {
		//this method delay loads tiptap javascript
		if(!this.editor) {
			if(this.element.querySelector('.mf_editor')._tippy) {
				 //Keep getting re-instantiating editors hopefully this fixes that - - -
				 // although I have no fin idea why this keeps happening and it bugs 
				 // me that it got to this point.
				this.editor = this.element.querySelector('.mf_editor')._tippy
			} else {
				let editorSetup = ((x) => {
					//create the editor
					let editor_element = this.element.querySelector('.mf_editor')
					let is_small = (midflip.pageSize == "small")
					let editor_finput = this.element.querySelector('.mf_editor_form')

					this.editor = x.default(
						editor_element,
						editor_finput,
						is_small,
						this,
					)

					if(this.editorLoadedPromiseResolve){this.editorLoadedPromiseResolve(this)}

					//create a submit event to update the form input parameter
					// this.element.addEventListener('save_text', ((e) => {
					// 	this.saveContent()
					// }).bind(this))
					//was replaced with _unit.saveContent()
				}).bind(this)

				import('../tiptap_midflip').then(editorSetup)
			}
		}
	},
	saveContent(){ //< used in application_controller#saveTiptaps
		if(this.editor){
			let editor_finput = this.element.querySelector('.mf_editor_form')
			editor_finput.setAttribute('value', this.editor.getHTML())
		}
	},

	// get_button_vars(event) {
	// 	let button = event.currentTarget
	// 	let type = button.getAttribute('data-tiptap')
	// 	let upperType = type[0].toUpperCase() + type.slice(1) //uppercase first letter
	// 	return [button, type, upperType]
	// },

	// ############### IMAGE DROPDOWN #################
	openImageDropdown(event){
		let button = event.currentTarget
		let id =  this.element.id + "_image_dropdown" 
		let menu = document.getElementById(id)
		menu.classList.toggle('disNone')
	},

	// ############### HEADER DROPDOWN ################
	openHeaderDropdown(event){
		let button = event.currentTarget
		let id =  this.element.id + "_header_dropdown" 
		let menu = document.getElementById(id)
		menu.classList.toggle('disNone')
	},

	toggle_paragraph_button(event){
		if(this.editor == undefined) { return }
		let button = event.currentTarget
		this.editor.chain().focus().toggleHeading({ level: level }).run()
	},

	toggleHeaderButton(event) {
		if(this.editor == undefined) { return }
		let butt = event.currentTarget
		let level = parseInt( butt.getAttribute('data-level') )
		
		//make changes
		this.editor.chain().focus().toggleHeading({ level: level }).run()
		//show the changes
		this.refreshHeaderActive(butt)	
	},
	refreshActive(bubble_menu){
		let buttons = bubble_menu.querySelectorAll('.editor-button')
		let butt
		for(let x = 0; x < buttons.length; x+=1){
			butt = buttons[x]
			if(butt.hasAttribute('data-level')) {
				this.refreshHeaderActive(butt)
			} else if (butt.hasAttribute('data-tiptap')){
				this.refresh_button_active(butt)
			}
		}
	},
	refreshHeaderActive(butt) {
		let level = parseInt( butt.getAttribute('data-level') )
		if(this.editor.isActive('heading', {level: level})) {
			butt.classList.add('active')
		} else {
			butt.classList.remove('active')
		}
	},
	async addDrop(){
		this.editor.commands.insertDrop()
	},
	toggleButton(event) {
		if(this.editor == undefined) { return }
		let butt = event.currentTarget
		let type = butt.getAttribute('data-tiptap')
		let upperType = type[0].toUpperCase() + type.slice(1) //uppercase first letter
		//make the changes
		this.editor.chain().focus()['toggle' + upperType]().run()
		//show the changes in the button's UI
		this.refresh_button_active(butt)
		event.preventDefault()
	},
	refresh_button_active(butt) {
		let type = butt.getAttribute('data-tiptap')
		if( this.editor.isActive(type)){
			butt.classList.add('active')
		} else {
			butt.classList.remove('active')
		}
	},
	addImage() {
		let url = window.prompt('Add image link')
		if(url == null || url == ''){return}
		url = url.trim()
		//we can also add an alt and a title if needed in setImage.
		this.editor.commands.setImage({src: url})
	},
	async addPrev(){
		//check if nil
		let src = prompt("Add a midflip link (post, topic, or comment)")
		if(!src){return}
		src = src.trim()
		if(!src){return}
		let linkify
		if(!document.is_local_url(src)){
			//check if naught local url. If so mark as link
			linkify = true
		} else {
			//if it is a local url, check if we can make a preview of it. If not mark as link
			let x = await document.tripwire('/api/preview', {
				method: "POST",
				form_info: {
					test_compat: true,
					src: src
				}
			})
			if( x == 'compat' ){
				linkify = false
			} else if (x == 'incompat') {
				linkify = true
			}
		}
		
		if(linkify){
			this.editor.commands.setLink({href: src})
			this.editor.commands.insertContent(src)
		} else {
			this.editor.commands.insertPreview({
				src: src
			})
		}
	},
	addYt(){
		let src = prompt("Add a youtube video link")
		if(src == null || src == ''){return}
		this.editor.commands.setYoutubeVideo({
			src: src,
			// width: 640,
			// height: 480,
		})		  
	},
	setLink(){
		let sel = this.editor.view.state.selection
		let existingHref = this.editor.isActive('link') ? this.editor.getAttributes('link').href : ''		
		if(!(sel.empty)) {
			if(existingHref) {
				//we are highlighting an existing link, so unlink it.
				this.editor.commands.toggleLink({href: existingHref})
			} else {
				//we are highlighting a non link, so toggle a link on that text.
				let src = prompt("Add a link url")
				this.editor.commands.toggleLink({href: src})	
			}
		} else {
			//nothing selected, set link and then paste the link so that
			//we paste a link which links
			let src = prompt("Add a link url")
			this.editor.commands.setLink({href: src})
			this.editor.commands.insertContent(src)
		}
	},
	imageUpload() {
		//1. ok bear with me. First we create a ghost input element, set the accept to images, and create a function
		//that handles the file when the file comes in. Then we 'click' it.
		let input = document.createElement('input');
		input.type = 'file'
		input.accept = "image/*"
		let context = this
		input.onchange = e => {
			//2. append the file as form_data with the file name
			let f = e.target.files[0]
			let f_size = f.size
			if(f.size >= 10000000) { //see the file_size_limitable.rb file
				alert('file is way too big!')
				return
			}
			let form_data = new FormData();
			form_data.append('image[image]', f, f.name);
			//3. use the meta quick_xhr method to submit the file
			document.quick_xhr('/user_images', form_data).then((json) => {
				//4. when the response comes back, set the image
				let url = json.location
				context.editor.commands.setImage({src: url})
			}, () => {
				//5. or error
				alert('upload failed')
			})
		}
		input.click()
	}
}