import ApplicationController from './application_controller'

export default class extends ApplicationController {
	remove_self(){
		let src = this.element.getAttribute('src')
		let a = document.createElement('a',  {href: src })
		a.appendChild(document.createTextNode(src))
		this.element.insertAdjacentElement('afterend', a)
		this.element.remove()
	}
	
	connect () {
		//tripwire with the src, replace the inner html with the title and text of the
		//appropriate model.
		if(this.element.dataset.noRend != null) { return }
		let other_prev = this.element.parentElement.closest("div[data-controller='preview']")
		let allow_other_preview = (other_prev == null)
		let e_cancel_f = this.remove_self.bind(this)
		let cancel_f = ((x) => {
			if(x == 'incompat'){
				e_cancel_f()
			}
		}).bind(this)

		let preview_url = new URL(this.element.getAttribute('src'))
		if(document.is_local_url(preview_url)) {
			document.tripwire('/api/preview', {
				base_element: this.element,
				method: "POST",
				form_info: {
					src: preview_url.toString(),
					allow_preview: allow_other_preview
				}
			}).then(cancel_f, e_cancel_f)
		}
	}
}