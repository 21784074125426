import ApplicationController from './application_controller'
export default class extends ApplicationController {
	connect () {
		let resizeObserver = new ResizeObserver(this.refresh_side_height.bind(this))
		resizeObserver.observe(this.element)
		this.refresh_side_height()
	}

	refresh_side_height() {
		let rowWidth = this.element.clientWidth
		let spacesAvailable = Math.floor(rowWidth / 35)
		if(this.last == spacesAvailable){return}
		this.last = spacesAvailable

		let children = this.element.children
		for (let i = 0; i < children.length; i++) {
			if(i >= spacesAvailable){
				children[i].classList.add('disNone');
			} else {
				children[i].classList.remove('disNone');
			}
		}
	}
}