import ApplicationController from './application_controller'

export default class extends ApplicationController {
	connect () {
		super.connect()
	}
	
	toggleClose(event){
		const button = event.target;
		const commentContainer = button.closest('.comment-container')
		if(commentContainer.classList.contains("maxgap")){
			commentContainer.classList.remove("maxgap")
		}
		else {
			commentContainer.classList.add("maxgap")
		}
		const nextCommentAndChildren = button.closest('.comment_and_children').nextElementSibling
		const commentback = nextCommentAndChildren.querySelector('.comment-back-mobile_0')
		if(commentback.classList.contains("noCommentBorder")){
			commentback.classList.remove("noCommentBorder")
		} else {
			commentback.classList.add("noCommentBorder")
		}
	}
}