import ApplicationController from './application_controller'
import checkbox_yes from '../images/icons/checkbox-yes.svg'
import checkbox_no from '../images/icons/checkbox-no.svg'

export default class extends ApplicationController {
	connect () {
		super.connect()
	}

	check(e){ //toggles
		let uii = this.element.elements.namedItem('use_irl_img')
		if(uii.getAttribute('value') == 'false') {
			let file_inp = this.element.elements.namedItem('irl_image')
			if(file_inp.hasAttribute('data-exists')) {
				this.check_to('irl')
			} else {
				this.irl_image_change(e)
			}
		} else {
			this.check_to('svg')
		}
	}

	check_to(type) {
		let irly = document.getElementById('check_irl')
		let irlt = document.getElementById('irl_top')
		let svgy = document.getElementById('check_svg')
		let svgt = document.getElementById('svg_top')
		let uii = this.element.elements.namedItem('use_irl_img')
		if(type == 'irl') {
			uii.setAttribute('value', 'true')
			// irly.classList.add('active')
			// svgy.classList.remove('active')
			// irly.parentElement.classList.add('active')
			// svgy.parentElement.classList.remove('active')
			svgt.classList.remove('active')
			irlt.classList.add('active')
			irly.querySelector('img').setAttribute('src', checkbox_yes)
			svgy.querySelector('img').setAttribute('src', checkbox_no)
		} else {
			uii.setAttribute('value', 'false')		
			// irly.classList.remove('active')
			// svgy.classList.add('active')
			// irly.parentElement.classList.remove('active')
			// svgy.parentElement.classList.add('active')
			irlt.classList.remove('active')
			svgt.classList.add('active')
			irly.querySelector('img').setAttribute('src', checkbox_no)
			svgy.querySelector('img').setAttribute('src', checkbox_yes)
		}
	}

	irl_image_change(e) {
		let f = e.currentTarget.closest('form')
		let file_inp = f.elements.namedItem('irl_image')
		let context = this
		file_inp.onchange = () => {
			context.check_to('irl')
			if(!file_inp.hasAttribute('data-exists')){
				file_inp.setAttribute('data-exists', true)
			}
			let reader = new FileReader()
			reader.onload = () => {
				let irl_img_area = document.getElementById('irl_img')
				irl_img_area.querySelector('img').
					setAttribute('src', reader.result)
				document.getElementById('irl_img_text').innerHTML = 'Change image'
			}
			reader.readAsDataURL(file_inp.files[0])
		}
		file_inp.click()
	}
}
