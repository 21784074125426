import ApplicationController from './application_controller'

// WHAT DOES IT DO????
// Probably should have named this CHAMP DROPDOWN CONTROLLER
// But the name is everywhere now soooooo...
// It controls the current champ and past champ dropdowns which are seperate in the post page show html. 

export default class extends ApplicationController {
	static targets = ["dropdownItemText", "editorContent", "sharedContent", "dropdownToggle", "dropdownItem", "dropdownMenu", "container", "submitEdit"];
	
	connect () {
		super.connect()
		this.setDropdownToggleText();
	}

	showSection(event) {
		// const sectionId = event.target.getAttribute("data-section");
		const sectionId = event.target.closest("[data-section]").getAttribute("data-section");
		this.setActiveSection(sectionId);
		this.setDropdownToggleText();
		this.dropdownMenuTarget.classList.remove("show");
		
		//for table-of-content reasons to do with ordering of ids, we must make sure that the 
		//visible thing is on the top. Cant move editor because it will re-instantiate while the current
		//unit still exists on it, and break.
		if(sectionId == "editor"){
			this.sharedContentTarget.insertAdjacentElement(
				'beforeend',
				this.sharedContentTarget
			)
		} else {
			this.sharedContentTarget.insertAdjacentElement(
				'afterbegin',
				this.sharedContentTarget
			)
		}
	}

	setActiveSection(sectionId) {
		// show content selected. 
		if (sectionId == "pastKing" || sectionId == "pastChangesWon"){
			// This is when we are dealling with the past champs and so have no editor. 
			//essentially we want no change. we just don't want to trigger editorContentTarget which
			// does not exist in this case.
			this.sharedContentTarget.classList.add("active");
		} else if (sectionId == "currentKing" || sectionId == "changesWon") {
			this.sharedContentTarget.classList.add("active");
			this.editorContentTarget.classList.remove("active");

		} else {
			this.editorContentTarget.classList.add("active");
			this.sharedContentTarget.classList.remove("active");
		}
		
		// Diffy classes for changes won
		this.containerTarget.classList.remove("see-changes", "see-removed");
		if (sectionId == "changesWon" || sectionId == "pastChangesWon"){
			this.containerTarget.classList.add("see-changes", "see-removed")
		}

		// Editor Submit button still need to add logic so that it only shows when a change has been made. 
		if (sectionId == "pastKing" || sectionId == "pastChangesWon"){
			// lol do nothing
		} else if (sectionId == "editor"){
			this.submitEditTarget.classList.add("active")
			this.submitEditTarget.classList.remove("inactiveEditor")
		} else {
			this.submitEditTarget.classList.add("inactiveEditor")
			this.submitEditTarget.classList.remove("active")
		}

		// Adjust dropdown to have active state. 
		this.dropdownItemTargets.forEach((item) => {
			item.classList.remove("active");
		});

		// this.dropdownMenuTarget.querySelector(`[data-section="${sectionId}"]`).classList.add("active");
		this.dropdownItemTargets.forEach((item) => {
			item.classList.toggle("active", item.getAttribute("data-section") === sectionId);
		});


    }

	setDropdownToggleText() {
		const activeSection = this.dropdownMenuTarget.querySelector(".dropdown-item.active");
		// const dropdownItemText = activeSection ? activeSection.querySelector("[data-post-status-target='dropdownItemText']") : null;
		// console.log("dropdownItemText", dropdownItemText)
		this.dropdownToggleTarget.textContent = activeSection ? activeSection.getAttribute("data-btn-text") : "Select Section";
	}

}