import ApplicationController from './application_controller'

//theoretically this should only load when used.

async function laze(el) {
	let { default: intlTelInput } = await import('intl-tel-input')
	let { default: utils} = await import('intl-tel-input/build/js/utils.js')
	intlTelInput(el, {
		onlyCountries: [
			"ai", //Anguilla
			"bm", //Bermuda
			"ca", //Canada
			"ky", //Cayman Islands
			"mx", //Mexico
			"ms", //Montserrat
			"pr", //puerto rico
			"tc", //turks and caicos islands
			"us", //United States
			"vg", //virgin islands br
			"vi", //virgin islands us
			"io", //British Indian Ocean Territory
			"cy", //Cyprus
			"kr", //The non insane Korea
			"jp", //Japan
			"at", //Austria
			"be", //Belgium
			"bg", //Bulgaria,
			"hr", //Croatia
			"cz", //Czechia / Czech Rep
			"dk", //Denmark
			"ee", //Estonia
			"fi", //Finland
			"fr", //France
			"de", //Germany
			"gi", //Gibraltar
			"gr", //Greece
			"hu", //Hungary
			"ie", //Ireland
			"it", //Italy
			"lv", //Latvia
			"lt", //Lithuania
			"lu", //Luxembourg
			"mt", //Malta
			"nl", //Netherlands
			"pl", //Poland
			"pt", //Portugal
			"ro", //Romania
			"sk", //Slovakia
			"si", //Slovenia
			"es", //Spain
			"se", //Sweden
			"gb", //United Kingdom
			"fk", //Falkland Islands
			"sh", //Saint Helena
			"as", //American Samoa
			"au", //Australia
			'gu', //Guam
			'nz', //New Zealand, and also i think pitcairn
			'mp', //Northern Mariana Islands
			'ws', //Samoa
		],
		utilsScript: utils,
		nationalMode: true,
	})
	return
}


export default class extends ApplicationController {
	connect () {
		// console.log('bleh')
		laze(this.element.childNodes[0])
	}
}

