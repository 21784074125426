import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = ["popArea"]
	connect () {
		super.connect()
	}
	common(different, event) {
		document.tripwire(different, {
			form_info: {
				'topic-context': this.element.getAttribute('data-context'),
				id: event.currentTarget.getAttribute("id"),
				up_id: this.element.getAttribute('id'),
				x: event.layerX,
				y: event.layerY
			}
		}).then(()=>{
			if(this.hasPopAreaTarget) {
				this.popAreaTarget.innerHTML = this.popAreaTarget.innerHTML + '<h1> JS:' + event.layerX + ' ' + event.layerY + '</h1>'
			}
		})
	}
	like(event) {
		this.common("/api/like", event)
	}
	dislike(event) {
		this.common("/api/dislike", event)
	}
}