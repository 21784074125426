import ApplicationController from './application_controller'
import checkbox_yes from '../images/icons/checkbox-yes.svg'
import checkbox_no from '../images/icons/checkbox-no.svg'
import checkbox_somewhat from '../images/icons/checkbox-somewhat.svg'


export default class extends ApplicationController {
	static targets = ["url"]
	connect() {
	}

	all_tag_toggle(event) {
		let element = event.currentTarget
		let cb = this.element.querySelector("[id='" + element.getAttribute("data-all-tag") + "']")
		if(cb.hasAttribute("style")) {
			cb.removeAttribute("style")
		} else {
			cb.setAttribute("style", "display: none;")
		}
	}

	get_status(element){
		return element.getAttribute('data-status')
	}

	update_urls(event) {
		let element = event.currentTarget
		// this.flip_toggle(element)

		//get the selectable (ex. category, filter, type...)
		let selectable = element.getAttribute('data-selectable')

		//get the active selectables
		//let active_selectables = this.get_active_selectables(selectable)

		//check if we need to flip the allselects
		
		//flip and set the status
		let status = this.reverse_flip(element)
		// console.log('status', status)

		//bubble up and down the changes
		let kids = this.get_kids(element)
		let parent = this.get_parent(element)
		this.bubble_down(kids, status)
		this.bubble_up(parent)
		
		let super_parent = this.get_super_parent_element(element)

		//get the active_values (which are also the element ids).
		//note that it does not include children ids/values of active parents
		let active_values = this.get_arg(super_parent)
		
		//flip the appropriate buttons
		if(active_values.length == 1) {
			this.change_selectable_button_to_element(selectable,  active_values[0])
		} else {
			this.change_selectable_button_to_custom(selectable)
		}

		//join into a url argument
		active_values = active_values.join('_')


		let path = element.getAttribute('data-path')
		this.change_urls(path, active_values, selectable)
	}
	get_super_parent_element(element) {
		//find the topmost parent
		let top_parent = undefined
		let x = element
		while(x != undefined) {
			top_parent = x
			x = this.get_parent(x)
		}
		return top_parent
	}
	get_arg(super_parent) {
		//i wrote this in a non-recursive format so that the upper categoires are showed first in the url.
		//aka it should look like library_science_buisness_lol_nw_pol and not
		//lol_nw_pol_library_science_buisness
		let values = []
		let kids = []
		let new_kids = [super_parent]
		while(new_kids.length > 0) {
			kids = new_kids
			new_kids = []
			for(let i = 0; i < kids.length; i++) {
				let kid = kids[i]
				let stat = this.get_status(kid)
				if(stat == 3){
					values.push(kid.id)
				} else {
					//concatonate the kid's kids
					new_kids = new_kids.concat(
						Array.from(this.get_kids(kid))
					)
				}
			}
		}
		return values
	}
	flip_to(element, status) {
		let checkbox = document.getElementById(element.id + "_checkbox")
		element.setAttribute('data-status', status)
		// console.log(status)
		if(status == 1) {
			checkbox.setAttribute('src', checkbox_no)
		} else if(status == 2) {
			checkbox.setAttribute('src', checkbox_somewhat)
		} else {
			checkbox.setAttribute('src', checkbox_yes)
		}
	}
	reverse_flip(element){
		let stat = this.get_status(element)
		if(stat <= 2) {
			this.flip_to(element, 3)
			return 3
		} else {
			this.flip_to(element, 1)
			return 1
		}
	}
	get_kids(parent) {
		return this.element.querySelectorAll("[data-parent='" + parent.id + "']")
	}
	get_parent(element) {
		return this.element.querySelector('[id="' + element.getAttribute("data-parent") + '"]')
	}
	bubble_down(kids, status) {
		//recursively 
		kids = Array.from(kids)
		for(let i = 0; i < kids.length; i++) {
			let kid = kids[i]
			this.flip_to(kid, status)
			this.bubble_down(this.get_kids(kid), status)
		}
	}
	bubble_up(parent) {
		//bubbles up activating or deactivating the parent elements.

		if(parent == null) {
			return false
		}
		//check if all kids are active, or if they are now inactive
		let kids = this.get_kids(parent)
		let status = this.get_status_by_kids(kids)
		
		//get the past checked value for the parent
		//let past_value = parent.getAttribute('data-status')
		
		//flip the parents data-active
		this.flip_to(parent, status)
		
		//bubble up to the parent's parent
		this.bubble_up(this.get_parent(parent))

		//we used to do changes and bubble up only sometimes, but with the somewhat checkbox that changed.
		//if(past_value != activeness) {
		//}
	}
	get_status_by_kids(element_array) {
		// 1 = all false
		// 2 = some false some true
		// 3 = all true
		let context = this
		let unique_stats = Array.from(
			new Set(
				Array.from(element_array).map(function(e){
					return context.get_status(e)
				})
			)
		)
		if(unique_stats.length > 1 || unique_stats[0] == 2) {
			return 2
		} else {
			return unique_stats[0]
		}
	}

	change_urls(path, value, selectable) {
		//get urls from other categories
		let other_selectable_urls = this.urlTargets.filter(function(e){
			return e.getAttribute("data-selectable") != selectable
		})

		//change those urls search parameter for that category
		other_selectable_urls.map(function(e) {
			// make url object
			let url = new URL(e.href)
			// change search parameter
			url.searchParams.set(selectable, value)
			if(path != undefined) {
				url.pathname = path
			}
			e.href = url.toString()
		})
	}
	change_selectable_button_to_custom(selectable) {
		//use stored data to change button to custom state
		let buttocks = document.getElementById(selectable + "_button")
		
		//buttocks.children[0].setAttribute("src", buttocks.getAttribute("data-custom-image"))
		buttocks.querySelector(".menu-writing").innerHTML = buttocks.getAttribute("data-custom-name")
	}
	change_selectable_button_to_element(selectable, element_id) {
		let element = this.element.querySelector('[id="' + element_id + '"]')
		//get appropriate button
		let buttocks = document.getElementById(selectable + "_button")
		//make changes to button image and text
		let el_im = document.getElementById(element.id + "_img")
		//buttocks.children[0].setAttribute("src", el_im.getAttribute("src"))
		buttocks.querySelector(".menu-writing").innerHTML = element.getAttribute("data-name")
	}
}