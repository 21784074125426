import ApplicationController from './application_controller'

export default class extends ApplicationController {
	// want to 1. store the id of the thing being dragged 2. show the thing is being dragged.
	// set css to important for override
	dragstart_f(ev) {
		ev.dataTransfer.clearData(); //< mdn did it so i do too. Seems important?
		let ct = ev.currentTarget
		ct.classList.add("being-dragged")
		ev.dataTransfer.setData("text/plain", ct.id)
	}
	dragend_f(ev){
		let ct = ev.currentTarget
		ct.classList.remove('being-dragged')
		ev.preventDefault()
	}
	//for metacats we want them to indicate that yes this is a droppable area. Set the css to important
	//so it overides other classes on it for now.
	dragenter_f(ev){
		let ct = ev.currentTarget
		ct.classList.add('category-hover-over')
		ev.preventDefault()
	}
	//... and then reset it again after
	dragleave_f(ev){
		let ct = ev.currentTarget
		ct.classList.remove('category-hover-over')

		ev.preventDefault()
	}
	remove_hover(){
		let arr = Array.from(document.querySelectorAll(".category-hover-over"))
		for(let el of arr) {
			el.classList.remove('category-hover-over')
		}
	}
	compat(ct, el){
		return (ct != el && !(el.contains(ct)))
	}
	//when dropped in valid category, add there. 
	drop_f(e){
		//get the element being dragged. In the case of meta categories, have to go up one.
		let id = e.dataTransfer.getData("text")
		let el = document.querySelector('[data-top=\'' + id + '\']')
		//get the target being dropped on and drop appropriately. For metacategories make a child
		//for categories make a sibling
		let ct = e.currentTarget.closest('[data-top]')
		if(this.compat(ct, el)){
			if(ct.dataset.cat != null){
				ct.insertAdjacentElement('afterend', el)
			} else {
				ct.querySelector('[data-cat-children]').insertAdjacentElement('afterbegin', el)
			}
		}
		//dragleave does not activate so make sure we kill hover classes.
		this.remove_hover()

		//let browser know we did things
		e.preventDefault()
	}
	end_drop_f(e){
		//drop thing at end of list
		let id = e.dataTransfer.getData("text")
		let el = document.querySelector('[data-top=\'' + id + '\']')
		let ct = e.currentTarget.closest('[data-top]')
		if(this.compat(ct, el)){
			ct.insertAdjacentElement('afterend', el)
		}

		//dragleave does not activate so make sure we kill hover classes.
		this.remove_hover()

		//tell browser we did things
		e.preventDefault()
	}
	dragover_f(e){
		e.preventDefault()
	}
	get_matching_parents(el){
		let x = el.dataset.parentId
		let z
		if(el == this.element) {
			return []
		} else if (x != null) {
			z = this.get_matching_parents(el.parentNode)
			z.push(x)
			return z
		} else {
			return this.get_matching_parents(el.parentNode)
		}
	}
	urln_upd(e){
		let ct = e.currentTarget
		let name = ct.textContent.trim()
		let ci = ct.closest('[data-cat-item]')
		let url_node = ci.querySelector('[data-name=\'url_n\']')
		if(name.length <= 1){ //if you select all and then hit 'a', then name will of never been empty
			url_node.dataset.auto = true
		}
		// console.log(url_node.dataset.auto)
		if(url_node.dataset.auto){
			let n = 3
			if(ci.dataset.metaCat != undefined) { n = 9 }
			url_node.textContent = name.toLowerCase().replace(/[^a-z]/g, '').substring(0, n)
		}
	}
	urln_change(e){
		e.currentTarget.dataset.auto = false
	}
	get_data_map(){
		let cat_items = Array.from(this.element.querySelectorAll('[data-cat-item]'))
		let h = []
		for(let i of cat_items){
			let htmp = h
			let p_arr = this.get_matching_parents(i)
			for(let pid of p_arr) {
				htmp = htmp.find(a => a[0] == pid)
				htmp = htmp[5]
			}
			let jsonified = [
				i.id,
				i.querySelector("[data-name='url_n']").textContent,
				i.querySelector("[data-name='name']").textContent,
				i.querySelector("[data-name='name']").textContent, //< it is what it is i suppose
				i.querySelector("[data-name='name']").textContent,
			]
			if(i.dataset.metaCat != null) {
				jsonified.push([])
			}
			htmp.push(jsonified)
		}
		return h
	}
	
	connect(){
		// note -prevent default often lets browser know we are doing things, so it can go
		//	ahead with its things too

		//drop		dropped on a valid target. A valid target being one that specifys both
		//	dragenter and dragover and prevents default of those methods.
		//dragend	drag operation finishes
		//dragenter	dragged item enters valid drop site
		//dragleave	leave valid drop site
		//dragover	currently being dragged over valid drop site (every 100ms)
		//dragstart	user starts dragging

		//for each cat item, make them draggable, droppable and responsive
		let all_cat_items = Array.from(this.element.querySelectorAll('[data-cat-item]'))
		for(let cat_item of all_cat_items) {
			this.cat_event_listeners(cat_item)
		}
		//the drop area at the bottom is for adding things back to the top of the list
		let below_drop_areas = Array.from(this.element.querySelectorAll('[data-below-drop]'))
		console.log(below_drop_areas)
		for(let bd of below_drop_areas){
			this.below_drop_event_listeners(bd)
		}
	}
	cat_event_listeners(cat_item){//used on connect and then when new ones added
		cat_item.addEventListener('drop', this.drop_f.bind(this))
		cat_item.addEventListener('dragover', this.dragover_f.bind(this))
		cat_item.addEventListener('dragstart', this.dragstart_f.bind(this))
		cat_item.addEventListener('dragend', this.dragend_f.bind(this))
		cat_item.addEventListener('dragenter', this.dragenter_f.bind(this))
		cat_item.addEventListener('dragleave', this.dragleave_f.bind(this))
	}
	below_drop_event_listeners(bd){//used on connect and then when new ones added
		bd.addEventListener('drop', this.end_drop_f.bind(this))
		bd.addEventListener('dragover', this.dragover_f.bind(this))
		bd.addEventListener('dragenter', this.dragenter_f.bind(this))
		bd.addEventListener('dragleave', this.dragleave_f.bind(this))
	}
	delay_trigger(e){
		// sends data map to the particular path and then updates with returned data.
		//has a delay functionality so we dont spam stuff
		if(this.lastWait != null) {
			clearTimeout(this.lastWait)
			 //wait another 1.5 seconds instead.
		}
		this.lastWait = setTimeout(this.trigger.bind(this), 1500, e, e.currentTarget)
	}
	trigger(e, ct){
		if(ct == null){
			ct = e.currentTarget
		}
		let dm = JSON.stringify(this.get_data_map())
		let pth = ct.dataset.path
		document.tripwire(pth, {
			base_element: this.element,
			form_info: {
				data_map: dm
			}
		}).then(data => {
			if(data==null){return}
			//throw event listeners on
			let el
			if(data['added_id'] != null) {
				let el = document.getElementById(data['added_id'])
				this.cat_event_listeners(el)
				if(el.dataset.metaCat!=undefined){
					let str = `[data-below-drop='${data['added_id']}']`
					this.below_drop_event_listeners(document.querySelector(str))	
				}
			}
			//remove previous error styles
			let arr = Array.from(this.element.querySelectorAll(".category-is-bad"))
			for(el of arr) {
				el.classList.remove('category-is-bad')
				el.classList.add('category-at-rest')
			}
			//add new error styles
			for(let id of data['bad_ids']) {
				el = document.getElementById(id)
				el.classList.remove('category-at-rest')
				el.classList.add('category-is-bad')
			}
		})
	}
}