import ApplicationController from './application_controller'

export default class extends ApplicationController {
	consent(event) {
		document.cookie = "cookie_eu_consented=true; max-age=" + 365*24*60*60 + "; path=/; secure"
		if(event.target.hasAttribute('data-rdir')) {
			otty.goto(event.target.getAttribute('data-rdir'))
		} else {
			this.element.parentNode.removeChild(this.element);
		}
	}
	burn() {
		//https://stackoverflow.com/a/27374365
		document.cookie.split(";").forEach(function(c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
		window.localStorage.clear()
		otty.goto("/this_guy_hates_cookies")
	}
}
