import ApplicationController from './application_controller'

//theoretically this should only load when used.
async function yeet() {
	const { Chart } = await import('chart.js/auto');
	return Chart
}

export default class extends ApplicationController {
	connect () {
		yeet().then((Chart) => {
			let dat = JSON.parse(this.element.dataset.chart)
			this.chart = new Chart(this.element, dat, dat['data'])
		})
	}
}

