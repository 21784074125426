import ApplicationController from './application_controller'
// This controller makes it so that the floating button row in the post page adjusts its width as the "changes explainer" auto closes after 2 seconds
export default class extends ApplicationController {
	static targets = ['animator', 'animatorParent']
	connect () {
		super.connect()
		console.log("YOYOYOYO");
		this.animatorTarget.addEventListener('animationend', this.adjustParentWidth)
	}

	disconnect() {
		this.animatorTarget.removeEventListener('animationend', this.adjustParentWidth)
	}
	
	adjustParentWidth = () => {
		
		let totalWidth = 0;
  
		Array.from(this.animatorParentTarget.children).forEach(child => {
			let style = window.getComputedStyle(child);
			let width = parseFloat(style.width);
			let marginLeft = parseFloat(style.marginLeft);
			let marginRight = parseFloat(style.marginRight);
			
			totalWidth += width + marginLeft + marginRight;
		});

		// Convert 0.5rem to pixels
		let style = window.getComputedStyle(document.body);
		let rem = parseFloat(style.fontSize);
		let padding = 0.5 * rem;

		totalWidth += padding;
		var context = this
		setTimeout(function(){
			context.animatorParentTarget.style.width = `${totalWidth}px`;
		}, 100)
		
		console.log("WOWOWOWOW");

	}
}