import TimeMe from 'timeme.js'
export default  {
	unitName: "Setup",
	onConnected() {
		TimeMe.resetAllRecordedPageTimes();
		TimeMe.initialize({
			currentPageName: window.location.pathname,
			idleTimeoutInSeconds: 45, //since we are long form in many cases
		})
		this.start_t = new Date().getTime()
		this.bef_path = window.location.href //can switch on pat
		
		this.postTimes = this.postTimes.bind(this) //< permabind the postTimes function to the unit

		//https://stackoverflow.com/questions/4945932/window-onbeforeunload-ajax-request-in-chrome
		window.addEventListener('beforeunload', this.postTimes)//<- this is when they press the x button. disconnect does not get called in that case.
		window.addEventListener('unload', this.postTimes)
	},
	postTimes(){
		//only do once despite us having 3 end states
		if(this.stopped){return}
		this.stopped = true

		let lgit200 = (z) => otty.isDev && console.log("XHR YE", z)
		let lgit = (z) => otty.isDev && console.log("XHR FAIL", z)
		let fuggit = otty.sendsXHR({
			url: '/api/times',
			formInfo: {
				path: this.bef_path,
				active_t: TimeMe.getTimeOnCurrentPageInSeconds(),
				start_t: this.start_t,
				tid: midflip.getTabId()
		}}).then(lgit200, lgit)
	},
	onRemoved() {
		window.removeEventListener('beforeunload', this.postTimes)
		window.removeEventListener('unload', this.postTimes)
		this.postTimes()
	}
}