
export default {
	unitName: "PricingTable",
	onConnected(){
		//pretty sure this does not do anything since we do it wit the additional_headers ruby thing.
		if(midflip.pricingTableLoaded == true){return}
		midflip.pricingTableLoaded = true
		fetch('https://js.stripe.com/v3/pricing-table.js', {method: 'GET'}).then((resp)=>{
			resp.text().then((code) => {
				window.eval(code)
			})
		})
	}
}