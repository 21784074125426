import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = ["checkbox", "continueBtn"]
	connect () {
	}

	toggle(){
		if (this.continueBtnTarget.classList.contains("greyed-continue")){
			this.continueBtnTarget.classList.remove("greyed-continue")
		}
		else{
			this.continueBtnTarget.classList.add("greyed-continue")
		}
	}

}