import ApplicationController from './application_controller'

var aaa = null
export default class extends ApplicationController {
	connect(){
		let context = this
		this.f2_active = false
		this.f2 = (e) => {
			if(!context.element.contains(e.target)){
				context.icon_close(context.element)
				if(this.f2_active) {
					document.removeEventListener('click', context.f2)
					this.f2_active = false
				}
			}
		}
		this.f1 = (e) => {
			if(!context.f2_active) {
				context.f2_active = true
				document.addEventListener('click', context.f2)
			}
		}
		this.element.addEventListener('focusin', context.f1)
	}
	disconnect(){
		if(this.f2_active) { document.removeEventListener('click', this.f2) }
		this.element.removeEventListener('focusin', this.f1)
	}
	icon_close(node) {
		//needs to be able to close other icons aswell not just ones inside of this icon controller but other icon controllers.
		console.log('icon_close', node)
		let icon_top = node.closest('[data-controller="icon"][data-state]')
		let dropdown = icon_top.querySelector('.user-icon-dropdown')
		if(dropdown == null) {
			return
		}
		dropdown.remove()
		let state = JSON.parse( icon_top.dataset.state)
		state['show_menu'] = false
		icon_top.dataset.state = JSON.stringify(state)
	}
	open_user_icon_menu(e) {
		console.log('open_user_icon_menu', this.element)
		//tripwire opens/closes, then we close others.
		let context = this
		this.tripwire_data(e).then(() => {
			for(let x of Array.from( document.querySelectorAll('.user-icon-dropdown') )) {
				if(x.closest('[data-controller="icon"][data-state]') != context.element) {
					context.icon_close(x)
				}
			}
		})
	}
}