import ApplicationController from './application_controller'

export default class extends ApplicationController {
	//not quite sure what the deets are here anymore, check MDN?
	connect () {
		if(midflip.deferredPrompt != undefined) {
			this.element.style.display = 'block'
		} else {
			midflip.deferredPromptAppears.push(this.element)
		}
	}
	install_app(event){
		midflip.deferredPrompt.prompt();
		midflip.deferredPrompt.userChoice.then(((choiceResult) => {
			if(choiceResult.outcome == "accepted") {
				this.element.style.display = 'none'
				// this.set_appearing_targets_to('none')
			}
		}).bind(this))
	}
}