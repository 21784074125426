import ApplicationController from './application_controller'

export default class extends ApplicationController {
	connect () {

	}
	check(e){
		let inp = this.element.childNodes[0]
		inp.value = inp.value.substr(0, this.element.dataset.limit)
	}
}