import Generic from '@luketclancy/otterly/units/generic.js'
export default {
	...Generic,
	diveInfo(e, h = {}){
		//this function is for formatting html data into a format for an otty dive. Behaviours:
		//	1. returns an object with the dive options, includexing the formInfo option (which has the information sent to the server)
		//		- if a dataset key is included in diveOptParams, it will be added to the options.
		//		- if a dataset key is included in diveErrParams, you will get a warning.
		//		- otherwise, it will be added as a form field in formInfo.
		//		('csrfHeader' key = the data-csrf-header parameter in the html. As csrfHeader is in diveOptParams, it will become an option.)
		//	2. formInfo includes unitId, submitterId, the units dataset, and the event's currentTarget dataset (with the currentTarget taking priority.)
		//	3. options:
		//		opts:		override default options hash (must still have formInfo object)
		//		data:		override the dataset aquisition
		//		formData:	override the default FormData object
		//		withform: 	This does a few things:
		//				- includes any input elements within the unit (not the form) in the request
		//              - if the data-path / data-method is not included, falls back to closest form's action/formaction/method/formethod html attitrubes
		//				- you can think of this boolean as saying "play nice with the html"
		//
		//	note that if there are any formData keys (through formData or inputs options) then function will return a FormData object.
		
		let defaults = {
			opts: {e: e, formInfo: {}},
			withform: false
		}

		//get the input data elements
		let data = this.relevantData(e)

		//parse the settings into appropriate locations
		h = { ...defaults, ...h	}
		h.opts = {...defaults.opts, ...h.opts} //regain e and formInfo
		let inp, els, k
		for(k of Object.keys(data)){
			if(this.diveOptParams.includes(k)){
				h.opts[k] = data[k]
			} else if(this.diveErrParams.includes(k)){
				console.error('bad key for diveDataset: ' + k)
			} else {
				h.opts.formInfo[k] = data[k]
			}
		}
		// if its a form treat inputs as natively as possible. Otherwise
		// if someone wants it to act form-like, then try that. Note
		// that in this case it doesn't work with everything...
		// (checkboxes for example). Usually fine though.
		if(this.element.nodeName == "FORM"){
			h.withform = true
			h.formData = new FormData(this.element)
		} else if(h.withform){
			els = Array.from(this.el.qsa('input'))
			if(this.el.nodeName == 'INPUT'){els.push(this.el)}
			for(inp of els) {
				if(inp.name != null && inp.value != null) {
					h.formData.append(inp.name, inp.value)
				}
			}
		}
		if(!(h.opts.url)){h.opts.url = h.opts.formInfo.path}
		if(h.withform){
			if(!( h.opts.url)){ h.opts.url = e.ct.getAttribute("formaction")}
			if(! (h.opts.url) ){ h.opts.url = e.ct.closest('form')?.getAttribute('action') }
		}
		if(!(h.opts.method)){h.opts.method = h.opts.formInfo.method}
		if(h.withform){
			if(!(h.opts.method)){ h.opts.method = e.ct.getAttribute("formmethod")}
			if(!(h.opts.method)){ h.opts.method = e.ct.closest('form')?.getAttribute('method') }
		}
		if(!(h.opts.method)){ h.opts.method = "POST"}

		h.opts.formInfo = otty.obj_to_fd(h.opts.formInfo, h.formData)

		return h.opts
	},
	dive(e, h = {}){
		let regular_dive = (e, h)=>{
			
			if(!h['allowDefault']){e.preventDefault()}

			let act
			if(h.behavior == undefined){h.behavior = 'default'}
			if(act = this.diveBehaviors[h.behavior]){
				return act.bind(this)(e, h)
			} else {
				console.error(`${h.behavior} is not a behavior type for a dive`)
			}
		}

		//before
		if(h['saveTiptaps']){midflip.saveTiptaps()}

		let inProgress = regular_dive.bind(this)(e, h)

		let after = () => {
			if(h['resetLoading']){
				midflip.kill_any_loading_butts_below(this.el)
			}
		}

		inProgress.then(after, after)
	},
}