export default {
	unitName: "GoogleButton",
	onConnected: async function(){
		otty.log('enter_gb_connect')
		this.obs = midflip.mob(this.el, (() => {
			otty.log('mob_hit')
			let fr = this.el.qs('iframe')
			if(fr.getAttribute('style').includes('-2px -10px;')){
				fr.setAttribute('style',
					(fr.getAttribute('style').
						split(';').
						filter((a) => {
							return !(a.includes('width') || a.includes('margin'))
						}).
						join(';')
					) + `; width: ${parseInt(this.el.dataset.width) + 20}px; margin: -2px -10px 0px 0px;`
				)
			}
			otty.log('mob_left')
		}).bind(this))
		otty.log('obs_defined')

		await midflip.getGoogle()
		otty.log('got_google')

		google.accounts.id.renderButton( this.el,  {
			theme: "outline",
			width: parseInt(this.el.dataset.width),
			type: "standard",
			size: "large",
			theme: "outline",
			text: "sign_in_with",
			shape: "rectangular",
			logo_alignment: "left"
		});

		otty.log('leave_bg_connect')
	},
	onRemoved(){
		otty.log('discon_s')
		if(this.obs){this.obs.disconnect()}
		this.el.innerHTML = ''
		otty.log('discon_e')
	}
}