import ApplicationController from './application_controller'
export default class extends ApplicationController {
	connect () {
		super.connect()
	}
	scroll_to_opened_area(tag, default_to_start) {
		if(this.element.getAttribute("data-type") == "single") {
			tag = "@dropdown_area"
		}
		this.scroll_to(this.element.dataset.object + tag, default_to_start)
	}
	scroll_to(id, default_to_start) {
		let el = document.getElementById(id)
		let h = {behavior: "smooth"}
		if (default_to_start || (el.clientHeight > window.innerHeight)) {
			h["block"] = "start"
		} else {
			h["block"] = "center"
		}
		document.getElementById(id).scrollIntoView(h)
	}
	show_follows(e) {
		this.tripwire_data(e).then(()=>{
			this.scroll_to_opened_area("@follows", false)
		})
	}
	show_report(e){
		this.tripwire_data(e).then(()=>{
			this.scroll_to_opened_area("@report_area", false)
		})
	}
}