
//enable service worker in dev
//	chrome: http: chrome://flags/#unsafely-treat-insecure-origin-as-secure -> http://localhost.io
//	firefox: devtools.serviceWorkers.testing.enabled

//https://onrails.blog/2021/03/02/rails-pwas-using-turbo-hhnpwa-7/
import ApplicationController from './application_controller'
export default class extends ApplicationController {
	connect() {
		if (navigator.serviceWorker) {
			//all the versions go to the same place, its just a way for the thing to update.
			let gotoo = `/service-worker-${midflip.serviceWorkerVersion}.js`

			// Registers (installs or updates) the service worker, and wait for it to become active
			navigator.serviceWorker.register(
					gotoo, { scope: "/"} //, updateViaCache: 'imports' 
				).then( (reg) => {
					// console.log("Service worker registered!", reg);
				}).catch( (error) => {
					if(midflip.isDev) {
						alert(error + '| only shows in development')
					}
					console.log(error)
				});
		} else {
			console.log('serviceWorker unavailable')
		}
	}
	controllerChange(event) {
		navigator.serviceWorker.controller.addEventListener(
			"statechange",
			this.stateChange.bind(this)
		);
	}
	stateChange() {
		
	}
}
