import ApplicationController from './application_controller.js'

export default class extends ApplicationController {
	static targets = ["container"]
	connect () {
	}
	close(){
		document.cookie = "vtip=true; path=/";
		this.containerTarget.classList.add("display-none")
	}
}