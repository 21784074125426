import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = ['top']
	connect () {
		super.connect()
		let context = this
		setTimeout(function(){
			if(context.hasTopTarget) {
				context.topTarget.classList.remove("prev-num")
				context.topTarget.classList.add("next-num")
			}
		}, 500, context)
	}
	
}