import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = ['content', 'butt']
	connect () {

	}

	copy(){
		navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
			if (result.state == "granted" || result.state == "prompt") {
				let content = this.contentTarget.innerHTML;
				// console.log("content:", content);
				let blob = Promise.resolve( new Blob([content], {type: "text/html"}) );
				// console.log("blob:", blob);
				let richTextInput = [new ClipboardItem({ "text/html": blob })];
				// console.log("richTextInput:", richTextInput);
				navigator.clipboard.write(richTextInput).then(()=>{/*console.log('a')*/}, () => {/*console.log('b')*/})

				this.buttTarget.classList.add("copiedAnimation")
				//aaa(richTextInput)
				var context = this
				setTimeout(function(){
					context.buttTarget.classList.remove("copiedAnimation")
				}, 2000)
			}
		  });
	}

}


