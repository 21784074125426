import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = ['button1', 'button2', 'button3', 'content1', 'content2', 'content3', "continueBtn1", "continueBtn2", "createBtnContainer"]
	connect () {
		super.connect()
		this.open = 'content1'
	}
	toContent1(e){
		if(this.open == "content1") { // <- this makes it flash instead of close! Yay! Bootstrap!
			this.continueBtn1Target.classList.remove("display-none")
			this.button1Target.classList.remove("collapsed")
			this.button1Target.classList.remove("collapsing")
			this.content1Target.classList.add("show")	
			return
		}
		this.open = "content1"

		this.button1Target.classList.remove("collapsed")
		this.content1Target.classList.add("show")
		this.continueBtn1Target.classList.remove("display-none")

		this.button2Target.classList.add("collapsed")
		this.content2Target.classList.remove("show")
		
		this.createBtnContainerTarget.classList.add("display-none")
	}
	// create_preview() {
	// 	this.saveTiptaps()
	// 	let para = new FormData( this.element.closest('form') )
	// 	document.tripwire('/api/precreate_preview', {
	// 		form_info: {
	// 			text:  para.get('post[text]' ),
	// 			title: para.get("post[title]" ),
	// 			type: 'topic_preview'
	// 		}
	// 	})
	// }
	toContent2(){
		if(this.open == "content2") {
			this.button2Target.classList.remove("collapsed")
			this.button2Target.classList.remove("collapsing")
			this.content2Target.classList.add("show")	
			return
		}
		this.open = "content2"
		// this.create_preview()

		this.button1Target.classList.add("collapsed")
		this.content1Target.classList.remove("show")
		this.continueBtn1Target.classList.add("display-none")

		this.button2Target.classList.remove("collapsed")
		this.content2Target.classList.add("show")

		this.createBtnContainerTarget.classList.remove("display-none")
	}
}