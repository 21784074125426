import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = [""]
	connect () {
		var paragraphs = document.querySelectorAll('.cycle-text');
		var activeIndex = 0;
		
		// setInterval auto loops
		setInterval(function() {
		paragraphs[activeIndex].classList.remove('active');

		activeIndex++;

		if (activeIndex >= paragraphs.length) {
			activeIndex = 0;
		}

		paragraphs[activeIndex].classList.add('active');
		}, 6000);
	}

}