import ApplicationController from './application_controller'

// import ApplicationController from './application_controller'
export default class extends ApplicationController {
	//solution -
	//	on connect, we shall go down through all the children and add a click event handler on all
	//	children that are links and that do not have a data-no-intercept.
	//	that event handler will richard_sherman that stuff. Also richard_sherman is not using pleb stimulus but
	//	instead will use chad tripwire.

	//issue
	//  - the links can not be opened in a new tab. There could be a better solution. On new page load, check for the type vs category filters.
	//    type means that we should end up on the feed. Category means its a filter of the main page (which has comments).
	//  - We will also do as above suggested in terms of the richard_sherman and the multiple event handlers dynamically allocated on connection.

	connect() {
		let context = this
		this.before_visit_method = (e) => {
			e.preventDefault()
			let url = new URL(e.currentTarget.getAttribute('href'),(new URL(window.location.href)).origin)
			context.richard_sherman(url.toString())
		}

		let arr = Array.from(this.element.querySelector('#intercept_at').querySelectorAll('a[href]'))
		let this_url = new URL(window.location.href)
		let next_url
		for(let linky of arr){
			//check to make sure the path of that link is the same as the path of this link besides
			//the ? values and then add the event listener
				//TODO
			next_url = new URL(linky.getAttribute('href'), this_url.origin)
			if(this_url.pathname == next_url.pathname && this_url.origin == next_url.origin) {
				linky.addEventListener('click', this.before_visit_method)
			}
		}
	}
	richard_sherman(url) {
		document.tripwire('/api/switch_topic_view', {
			form_info: {
				'data-feed': this.element.getAttribute('data-feed'),
				'url': url,
				'hgid': this.element.getAttribute('data-hgid'),
				'referer': this.element.getAttribute('data-referer'),
				'output_type': 'inner_feed' //<---- switches just the main feed
			}
		}).then(() => {
			//this was in the scroll controller and i just decided to keep it here so.
			let l = document.getElementById("left_side")
			if(l != undefined) {
				let r = document.getElementById("right_side")
				let h = document.getElementById("feed")
				l.style.height = h
				r.style.height = h
			}
		})
	}
	switch() {
		let f = this.element.getAttribute('data-feed')
		let d =  document.getElementById("switchFeedBtn")
		if( f == 'CommentFeed' ) {
			f = "TopicFeed"
		} else {
            f = "CommentFeed"
        }
		let urllll = window.location.href.split('?')[0] //we do this to disable the slidey animation that happens on feed change
														//by having it seem like nothing changed from the last menu selection.
														//Yes we are at the stage of the project where everything is gross.
		document.tripwire('/api/switch_topic_view', {
			form_info: {
				'data-feed': f,
				'url': urllll,
				'hgid': this.element.getAttribute('data-hgid'),
				'referer': urllll,
				'output_type': 'inner_page' //<----- switches the main feed and the sidecards
			}
		})
		
	}
}