export default {
	unitName: "Mention",
	onConnected() {
		if(!this.con){
			this.con=true
			this.el.addEventListener('click', () => otty.goto(`/user_pages/${this.el.dataset.id}`))
		}
	}
}

//data-controller data-unit
//data-action='click->Mention#do_thing data-on="click->do_thing[5]"
//