import ApplicationController from './application_controller'
import debounce from 'lodash/debounce'


export default class extends ApplicationController {
	static targets = ["other", "image"]
	connect () {
		var context = this
		super.connect()
		this.resize()

		context.resize = debounce(context.resize.bind(context), 200)
		window.addEventListener('resize', context.resize)

		// attempt to load slow
		// if(!context.element.hasAttribute('data-previously-loaded')){
		// 	setTimeout(function(context){
		// 		context.resize()
		// 	}, 350, context);
		// 	!context.element.setAttribute('data-previously-loaded', true)
		// }
		
		setTimeout(function(context){
			context.resize()
		}, 350, context);

		//orignal coad
		// this.resize = debounce(this.resize.bind(this), 200)
		// window.addEventListener('resize', this.resize)
	}

	resize () {
		// console.log('bleh', this)
		let a = this.otherTarget.offsetHeight //find the height of the other
		// console.log('a:', a)
		this.imageTarget.style.maxHeight = ("calc(" + a + "px" + " + 2rem)")	
	}
}


