import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = ["container"]
	connect () {
		const phraseContainer = this.containerTarget;
		const phrase = phraseContainer.dataset.phrase;
		const highlight1 = phraseContainer.dataset.highlight1;
		const highlight2 = phraseContainer.dataset.highlight2;
		const highlight3 = phraseContainer.dataset.highlight3;
		// const screenWidth = window.innerWidth;
		// const phraseWidth = phraseContainer.offsetWidth;
		const repeatCount = 25 //Math.ceil(screenWidth / phraseWidth);
		// if (repeatCount < 15){
		// 	repeatCount == 15
		// }

		const words = phrase.split(' ');
		const wordIndex1 = words.indexOf(highlight1);
		const wordIndex2 = words.indexOf(highlight2);
		const wordIndex3 = words.indexOf(highlight3);
		words[wordIndex1] = `<span class="PR_highlight">${words[wordIndex1]}</span>`;
		words[wordIndex2] = `<span class="PR_highlight">${words[wordIndex2]}</span>`;
		words[wordIndex3] = `<span class="PR_highlight">${words[wordIndex3]}</span>`;
		// const repeatedPhrase = words.join(' ').repeat(repeatCount);


		const repeatedPhrase = (words.join(' ') + '&nbsp;'.repeat(3)).repeat(repeatCount - 1);

		phraseContainer.innerHTML = repeatedPhrase;
		// this.im_done = true
	}

}