import ApplicationController from './application_controller'
export default class extends ApplicationController {
	connect () {
		super.connect()
		const editorDrops = document.querySelectorAll('.editorDrop');
		const editorDropsWithDiffAdded = Array.from(editorDrops).filter(editorDrop => editorDrop.querySelector('.diff_added'));
		const editorDropsWithDiffRemoved = Array.from(editorDrops).filter(editorDrop => editorDrop.querySelector('.diff_removed'));
	
		editorDropsWithDiffAdded.forEach(editorDrop => {
			editorDrop.classList.add('diffAdded_highlight');
		});

		editorDropsWithDiffRemoved.forEach(editorDrop => {
			editorDrop.classList.add('diffRemoved_highlight');
		});

	}

}