import ApplicationController from './application_controller'
export default class extends ApplicationController {
	static targets = ["sidebar"]
	connect() {
		this.scrollbarTimeout = null
		this.detectDarkMode()
    	this.addDarkModeListener()
		this.lastScrollTop = 0
    	this.scrollThrottleTimer = null
	}
	disconnect() {
		this.removeDarkModeListener()
	}
	detectDarkMode() {
    	this.updateDarkModeClass(midflip.isDm())
	}
	addDarkModeListener() {
		this.darkModeHandler = (e) => this.updateDarkModeClass(e.matches)
		window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.darkModeHandler)
	}
	removeDarkModeListener() {
		if (this.darkModeHandler) {
			window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.darkModeHandler)
		}
	}
	updateDarkModeClass(isDarkMode) {
		const sidebarContent = this.sidebarTarget.querySelector('.sidebar-content')
		if (sidebarContent) {
			if (isDarkMode) {
				sidebarContent.classList.add('sidebar_dark')
			} else {
				sidebarContent.classList.remove('sidebar_dark')
			}
		}
	}
	showScrollbar() {
		this.sidebarTarget.classList.add('show-scrollbar')

		clearTimeout(this.scrollbarTimeout)
		this.scrollbarTimeout = setTimeout(() => {
		this.hideScrollbar()
		}, 2000) // Hide scrollbar after 1 second of inactivity
	}
	hideScrollbar() {
		this.sidebarTarget.classList.remove('show-scrollbar')
	}
	scroll() {
		this.showScrollbar()
	}
	// Event handler for mouseleave
	mouseLeave() {
		clearTimeout(this.scrollbarTimeout)
		this.hideScrollbar()
	}
}