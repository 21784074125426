import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = ['arrow', 'arrowLeft','scrollableContainer', 'scrollable', 'containerContainer']
	connect () {
		super.connect()
		this.isMouseOver = false

		let contWidth = this.containerContainerTarget.offsetWidth
		let scrollableWidth = this.scrollableTarget.offsetWidth

		// console.log("container width:", contWidth)
		// console.log("scrollContainer width", scrollableWidth)

		if (scrollableWidth > contWidth) {
			this.arrowTarget.classList.remove("disNone")
		}
	}

	mouseOver(){
		this.IsMouseOver = true;
		// console.log("mouse OVER")
	}

	mouseLeave(){
		this.IsMouseOver = false;
		// console.log("mouse Leave")
	}

	scroll(event){
		// console.log("scroll")
		event.preventDefault();
		if (this.IsMouseOver) {
			const delta = event.deltaY;
			this.scrollableContainerTarget.scroll({top:0, left: this.scrollableContainerTarget.scrollLeft + delta, behavior: "smooth"})
		}
	}


	scrolljump(){
		// console.log("scroll jump!")
		// this.scrollableContainerTarget.scrollLeft += 200
		this.scrollableContainerTarget.scroll({top:0, left: this.scrollableContainerTarget.scrollLeft + 200, behavior:"smooth"})
		if (this.arrowLeftTarget){
			this.arrowLeftTarget.classList.remove("disNone")
		}
		const div = document.querySelector(".margin-left-25")
		div.style.marginLeft=  "6rem";
	}

	scrolljumpleft(){
		this.scrollableContainerTarget.scroll({top:0, left: this.scrollableContainerTarget.scrollLeft - 200, behavior:"smooth"})
	}

}