import ApplicationController from './application_controller'

export default class extends ApplicationController {
	static targets = ["container", "closeBtn"]
	connect () {
		let context = this
		this.rand = Math.floor(Math.random() * (50000) + 10000)
		setTimeout(function(context){
			context.containerTarget.classList.remove("display-none")
			context.containerTarget.classList.add("slide-in")
		}, this.rand, context);
	}

	close(){
		this.containerTarget.classList.add("display-none")
	}

}